import { Amount } from '@core/forms/validators/amount';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { t } from '@lingui/macro';
import { IsNotEmpty, IsString, Validate } from 'class-validator';

export interface ITopUpForm {
  walletId: string;
  paymentMethodId: string;
  amount: number;
}

export class TopUpFormFields implements ITopUpForm {
  @IsNotEmpty({ message: t`This field is mandatory` })
  walletId: string;

  @Validate(Amount)
  amount: number;

  @IsString()
  @IsNotEmpty()
  paymentMethodId: string;
}

export const TopUpResolver = classValidatorResolver(TopUpFormFields);
