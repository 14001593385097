import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';

export function styles(theme: Theme) {
  return appCreateStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      paddingBottom: '85px',
      '&:has(.without-bottom-padding)': {
        paddingBottom: '0px',
      },
    },
  });
}
