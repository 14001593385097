import React, { FC } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { DesktopContetLayout } from '@modules/new-private/layout/desktop-content-layout';
import { ROUTES } from '@shared/constants/routes';

// eslint-disable-next-line import/namespace,import/default
import TopUp from '../top-up/top-up';

import { styles } from './top-up-desktop-container.styles';

export interface DocumentsDesktopContainerProps extends AppWithStyles<typeof styles> {}

const DocumentsDesktopContainerComponent: FC<DocumentsDesktopContainerProps> = ({ classes }) => {
  const routes = [
    { label: t`Accounts`, path: ROUTES.desktopPrivate.dashboard },
    { label: t`Top Up`, path: ROUTES.desktopPrivate.wallets.topUp, disabled: true },
  ];

  return (
    <DesktopContetLayout links={routes}>
      <div className={classes.content}>
        <TopUp />
      </div>
    </DesktopContetLayout>
  );
};
export default appWithStyles(styles)(DocumentsDesktopContainerComponent);
