import { appInject } from '@core/di/utils';
import { appMakeObservable, appObservable } from '@core/state-management/utils';
import { t } from '@lingui/macro';
import { DI_TOKENS } from '@shared/constants/di';
import { CreditCardListModel } from '@shared/models/credit-card/list-model';
import { HttpErrorResponse } from '@shared/models/error/http-error-response';
import { IAuthService } from '@shared/types/auth-service';
import { ICardsService } from '@shared/types/card-service';
import { INotificationService } from '@shared/types/notification-service';
import { IPaymentProvider } from '@shared/types/payments/payment-provider.interface';
import { IProductsVM } from '@shared/types/products-vm';
import { IUsersService } from '@shared/types/users-service';
import { getFirstMetalName } from '@shared/utils/products';

export class TabbarViewModel {
  private cardService = appInject<ICardsService>(DI_TOKENS.cardService);
  private productsVM = appInject<IProductsVM>(DI_TOKENS.productsVM);
  private usersService = appInject<IUsersService>(DI_TOKENS.usersService);
  private paymentService = appInject<IPaymentProvider>(DI_TOKENS.paymentService);
  private authService = appInject<IAuthService>(DI_TOKENS.authService);
  private notificationsService = appInject<INotificationService>(DI_TOKENS.notificationService);
  private _maintenanceMode = {
    isActive: false as boolean,
  };
  activePaymentSystem() {
    return this.paymentService.name;
  }

  constructor() {
    appMakeObservable(this, {
      _maintenanceMode: appObservable,
    });
  }

  get isActiveMaintenanceMode() {
    return this._maintenanceMode.isActive;
  }

  get isNeedKYC() {
    return this.usersService.isNeedKYC;
  }

  get products() {
    return this.productsVM.products;
  }

  get smartCoinName() {
    return getFirstMetalName(this.products);
  }

  checkMaintenanceStatus() {
    this._maintenanceMode.isActive = this.usersService.isActiveMaintenanceMode;
  }

  handleAddNewCard = async (
    paymentInfo: any,
    save: boolean,
  ): Promise<CreditCardListModel | null> => {
    let savedCard = null;
    try {
      const { email } = await this.authService.getUserInfo(true);
      const newPaymentMethod = await this.paymentService.createPaymentMethod(
        Object.assign(paymentInfo, { email, isTemporary: !save }),
      );
      savedCard = await this.cardService.saveCard(newPaymentMethod);
    } catch (e: HttpErrorResponse | any) {
      const { response, message } = e;

      if (response) {
        throw response.findFirstErrorMessage() || message;
      } else {
        throw message || t`Your card was declined.`;
      }
    }
    return savedCard;
  };

  async fetchMaintenanceModeStatus() {
    await this.usersService.fetchMaintenanceModeStatus();
    this._maintenanceMode.isActive = this.usersService.isActiveMaintenanceMode;
  }

  showSuccess(text: string) {
    this.notificationsService.showSuccess(text);
  }

  showError(text: string) {
    this.notificationsService.showError(text);
  }
}
