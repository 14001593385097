import React from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { Theme, useMediaQuery } from '@mui/material';
import { Layout } from '@shared/utils/layout';

import { styles } from './transactions.styles';

export interface TransactionsProps extends AppWithStyles<typeof styles> {}

const Transactions: React.FC<TransactionsProps> = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Layout.tablet));
  if (isMobile) {
    return <div>MOBILE TRANSACTIONS LIST</div>;
  } else {
    return <div>DESKTOP TRANSACTIONS LIST</div>;
  }
};

export default appWithStyles(styles)(Transactions);
