import React, { FC } from 'react';

import { appInject } from '@core/di/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { Box, Drawer, Modal, Theme, useMediaQuery } from '@mui/material';
import { CloseDrawerButton } from '@shared/components/new-design/close-drawer-button/close-drawer-button';
import { WalletInfo } from '@shared/components/new-design/wallet-info';
import { comingSoonProducts } from '@shared/constants/coming-soon';
import { DI_TOKENS } from '@shared/constants/di';
import { WalletsListModel } from '@shared/models/wallets/list-model';
import { IProductsVM } from '@shared/types/products-vm';
import { Layout } from '@shared/utils/layout';

import { styles } from './wallets-dialog.styles';

export interface WalletsDialogProps extends AppWithStyles<typeof styles> {
  isOpen: boolean;
  onClose: () => void;
  onSelectWallet: (value: string) => void;
  wallets: Array<WalletsListModel>;
  title: string;
  description: string;
  metals: Array<WalletsListModel>;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  background: '#291945',
  border: '1px solid #473763',
  borderRadius: '10px',
  '&:focus-visible': {
    outline: 'none',
  },
};

const WalletsDialogComponent: FC<WalletsDialogProps> = ({
  classes,
  onClose,
  isOpen,
  wallets,
  onSelectWallet,
  title,
  description,
  metals,
}) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Layout.tablet));
  const productsVM = appInject<IProductsVM>(DI_TOKENS.productsVM);

  const limitComponent = (
    <div className={classes.viewBlock}>
      <div className={classes.title}>{title}</div>
      <div className={classes.description}>{description}</div>
      {metals.length > 0 ? (
        <div className={classes.details}>
          {wallets.length > 0 ? (
            <div className={classes.sectionTitle}>{t`Metal tokens`}</div>
          ) : (
            <></>
          )}
          {metals.map((w) => {
            const product = productsVM.getProductByCurrency(w.asJson.currency);
            if (!product) return <></>;
            return (
              <div className={classes.walletBlock} onClick={() => onSelectWallet(w.asJson.id)}>
                <WalletInfo wallet={w} product={product} hasBalance={true} />
              </div>
            );
          })}
        </div>
      ) : (
        <></>
      )}
      {wallets.length > 0 ? (
        <div className={classes.details}>
          {metals.length > 0 ? <div className={classes.sectionTitle}>{t`Fiat tokens`}</div> : <></>}
          {wallets.map((w) => {
            const product = productsVM.getProductByCurrency(w.asJson.currency);
            if (!product) return <></>;
            return (
              <div className={classes.walletBlock} onClick={() => onSelectWallet(w.asJson.id)}>
                <WalletInfo wallet={w} product={product} hasBalance={true} />
                <div
                  className={classes.walletDescription}
                >{t`Account based on ${product?.asJson.baseCurrency} price`}</div>
              </div>
            );
          })}
          {comingSoonProducts.map((comingSoonProduct) => {
            return (
              <div className={`${classes.walletBlock} ${classes.unactive}`}>
                <WalletInfo
                  wallet={comingSoonProduct as any}
                  product={comingSoonProduct as any}
                  unactive={true}
                  hasBalance={true}
                />
                <div className={classes.walletDescription}>{t`Coming soon...`}</div>
              </div>
            );
          })}
        </div>
      ) : (
        <></>
      )}
    </div>
  );

  let content = <></>;

  if (isMobile) {
    content = (
      <Drawer anchor={'bottom'} open={isOpen} className={classes.drawer} onClose={onClose}>
        <div className={classes.drawerContent}>{limitComponent}</div>
        <CloseDrawerButton onClick={onClose} />
      </Drawer>
    );
  } else {
    content = (
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>{limitComponent}</Box>
      </Modal>
    );
  }
  return <div className={classes.root}>{content}</div>;
};

export default appWithStyles(styles)(WalletsDialogComponent);
