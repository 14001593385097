import React, { FC, KeyboardEvent, useEffect, useMemo, useState } from 'react';

import { appObserver } from '@core/state-management/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { DocumentDialog } from '@modules/new-private/orders/purchase-and-sell/document-dialog';
import {
  ITopUpForm,
  TopUpFormFields,
  TopUpResolver,
} from '@modules/new-private/wallets/top-up/components/top-up/top-up.validator';
import { TopUpViewModel } from '@modules/new-private/wallets/top-up/components/top-up/top-up.vm';
import { WalletsDialog } from '@modules/new-private/wallets/top-up/components/wallets-dialog';
import { TextFieldProps, Theme, useMediaQuery } from '@mui/material';
import { BlockUserErrorDialog } from '@shared/components/block-user-error-dialog';
import { BackButton } from '@shared/components/new-design/back-button/back-button';
import { InputCurrency } from '@shared/components/new-design/input-currency-text';
import { InputSelectButton } from '@shared/components/new-design/input-select-button';
import { KYCDialogDialog } from '@shared/components/new-design/kyc-dialog/kyc-dialog';
import { SelectPaymentMethod } from '@shared/components/new-design/select-payment-method';
import { SelectedPaymentMethod } from '@shared/components/new-design/select-payment-method/selected-payment-method.type';
import SubmitButton from '@shared/components/new-design/submit-button/submit-button';
import { VerificationDialogDialog } from '@shared/components/new-design/verification-dialog/verification-dialog';
import { AddCardStripe } from '@shared/components/payments/stripe/add-card-stripe-component';
import { AddCardWorldpay } from '@shared/components/payments/worldpay/add-card-worldpay-component';
import { useNavigate } from '@shared/components/router';
import { ROUTES } from '@shared/constants/routes';
import { DocumentsTitle } from '@shared/enums/documents-title.enum';
import { ErrorKeysEnum, HttpErrorResponse } from '@shared/models/error/http-error-response';
import { MultiMetalPaymentType } from '@shared/models/orders/payment-method';
import { ResponsePaymentResult } from '@shared/models/orders/status-model';
import { ProductModel, ProductType } from '@shared/models/products/product-model';
import { DepositError } from '@shared/models/wallets/deposit-error';
import { WalletsListModel } from '@shared/models/wallets/list-model';
import { DepositStatusModel } from '@shared/models/wallets/status-model';
import { NamedError } from '@shared/types/named-error';
import { PaymentProviderEnum } from '@shared/types/payments/payment-provider.enum';
import { Layout } from '@shared/utils/layout';
import { amountToNumber } from '@shared/utils/metals';
import { nameOf } from '@shared/utils/nameof';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import ArrowIcon from '../../img/arrow.svg';
import LimitsInfoDialog from '../limits-info-dialog/limits-info-dialog';

import { styles } from './top-up.styles';

export interface TopUpProps extends AppWithStyles<typeof styles> {}

const TopUpComponent: FC<TopUpProps> = appObserver(({ classes }) => {
  const useQueryString = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQueryString();
  const defaultCurrency = query.get('currency') || '';
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Layout.tablet));
  const navigate = useNavigate();
  const $vm = useMemo(() => new TopUpViewModel(), []);
  const [isOpenAddNewCard, setIsOpenAddNewCard] = useState(false);
  const [isOpenWalletsDialog, setIsOpenWalletsDialog] = useState(false);
  const [isOpenPaymentMethod, setIsOpenPaymentMethod] = useState(false);
  const [isOpenLimitsInfo, setIsOpenLimitsInfo] = useState(false);
  const [amount, setAmount] = useState<string>('');
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<SelectedPaymentMethod>();
  const [activeWallet, setActiveWallet] = useState<WalletsListModel | null>(null);
  const [activeProduct, setActiveProduct] = useState<ProductModel | null>(null);
  const [isOpenDocumentDialog, setIsOpenDocumentDialog] = useState<boolean>(false);

  const [blockUserErrorMessage, setBlockUserErrorMessage] = useState<string>('');
  const [isOpenVerificationDialog, setIsOpenVerificationDialog] = useState<boolean>(false);
  const [isOpenKYCDialog, setIsOpenKYCDialog] = useState<boolean>(false);

  const inputRef = React.useRef<(TextFieldProps & { focus: () => void }) | null>(null);

  useEffect(() => {
    if ($vm.wallets.length) {
      const data: { amount: number; paymentMethodId: string; walletId: string } = $vm.loadState();
      if (data.amount && data.paymentMethodId && data.walletId) {
        handleSelectAmount(`${data.amount}`);
        const wallet = $vm.wallets?.find((w) => w.asJson.id === data.walletId);
        if (wallet) {
          setActiveWallet(wallet);
        }
        const card = $vm.cards?.find((c) => c.asJson.id === data.paymentMethodId);
        if (card) {
          onSelectPaymentMethod({
            paymentType: MultiMetalPaymentType.card,
            paymentMethod: card,
          });
        }
      }
      $vm.removeState();
    }
  }, [$vm.wallets.length]);

  const {
    getValues,
    trigger,
    setValue,
    handleSubmit,
    formState: {},
  } = useForm<TopUpFormFields>({ resolver: TopUpResolver });

  const fetchMaintenanceModeStatusQuery = useQuery(
    ['fetch-maintenance-mode-status'],
    () => $vm.fetchMaintenanceModeStatus(),
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
    },
  );

  const navigateToPreviousStep = () => {
    isMobile ? navigate(ROUTES.mobilePrivate.dashboard) : navigate(ROUTES.desktopPrivate.dashboard);
  };

  const handleMaintenancePage = () => {
    isMobile
      ? navigate(ROUTES.mobilePrivate.maintenancePage)
      : navigate(ROUTES.desktopPrivate.maintenancePage);
  };

  const downloadWalletsQuery = useQuery(['download-wallets-list'], () => $vm.downloadWallets(), {
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
    onError: () => {},
  });

  const downloadCardsQuery = useQuery(['download-cards-list'], () => $vm.downloadCards(), {
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
    onError: () => {},
  });

  const downloadLimitsQuery = useQuery(['download-limits'], () => $vm.downloadLimits(), {
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
    onSuccess: () => {},
    onError: () => {},
  });

  useQuery(['fetch-document'], () => $vm.fetchDocument(), {
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
  });

  const addCardMutation = useMutation(
    (data: { paymentInfo: any; save: boolean }) =>
      $vm.handleAddNewCard(data.paymentInfo, data.save),
    {
      onSuccess: (paymentMethod) => {
        if (paymentMethod) {
          setIsOpenAddNewCard(false);
          setIsOpenPaymentMethod(true);
          $vm.showSuccess(t`Card successfully added.`);
        }
      },
      onError: () => {
        $vm.showError(t`Your card was declined.`);
      },
    },
  );

  const createDepositWalletMutation = useMutation(
    (data: { paymentMethodId: string; amount: number; walletId: string }) =>
      $vm.createDepositWallet(data),
    {
      onSuccess: async (deposit) => {
        const { response, requiresFurtherAction, transactionId } = deposit.asJson;

        if (requiresFurtherAction) {
          handle3DSecureMutation.mutate({ transactionId, paymentResult: response });
        } else {
          navigate(ROUTES.mobilePrivate.dashboard);
          $vm.showSuccessfulTransactionMessage(
            getValues().amount,
            activeWallet ? activeWallet.asJson.currency : '',
          );
        }
      },
      onError: (e: HttpErrorResponse | any) => {
        switch (true) {
          case e.hasError(ErrorKeysEnum.User_Cannot_Be_Recipient):
            $vm.showCanceledTransactionMessage(
              getValues().amount,
              activeWallet ? activeWallet.asJson.currency : '',
            );
            break;
          case e.hasError(ErrorKeysEnum.User_Not_Verified):
            setIsOpenVerificationDialog(true);
            break;
          case e.hasError(ErrorKeysEnum.Recipient_Blocked):
            setBlockUserErrorMessage(e.findErrorMessageByKey(ErrorKeysEnum.Recipient_Blocked));
            break;
          default:
            $vm.showCanceledTransactionMessage(
              getValues().amount,
              activeWallet ? activeWallet.asJson.currency : '',
            );
            break;
        }
      },
    },
  );

  const confirmMutation = useMutation(
    (data: { transactionId: string }) => $vm.handleConfirmation(data.transactionId),
    {
      onSuccess: () => {
        navigate(ROUTES.mobilePrivate.dashboard);
        $vm.showSuccessfulTransactionMessage(
          getValues().amount,
          activeWallet ? activeWallet.asJson.currency : '',
        );
      },
      onError: async (error: NamedError) => {
        if (error.name === DepositError.Unconfirmed) {
          const confirmationError = error as NamedError<DepositStatusModel>;
          const depositStatus = confirmationError.data as DepositStatusModel;
          handle3DSecureMutation.mutate({
            transactionId: depositStatus.paymentResult.transactionId,
            paymentResult: depositStatus.paymentResult.response,
          });
        } else if (error.name === DepositError.Canceled) {
          $vm.showCanceledTransactionMessage(
            getValues().amount,
            activeWallet ? activeWallet.asJson.currency : '',
          );
        } else {
          $vm.showCanceledTransactionMessage(
            getValues().amount,
            activeWallet ? activeWallet.asJson.currency : '',
          );
          throw new Error('case not covered');
        }
      },
    },
  );

  const handle3DSecureMutation = useMutation(
    (data: { transactionId: string; paymentResult: ResponsePaymentResult }) =>
      $vm.handle3DSecure(data.transactionId, data.paymentResult),
    {
      onSuccess: async (result, variables) => {
        if (result && result.error) {
          await $vm.declineTransaction(variables.transactionId);
          $vm.showCanceledTransactionMessage(
            getValues().amount,
            activeWallet ? activeWallet.asJson.currency : '',
          );
        } else {
          confirmMutation.mutate({ transactionId: variables.transactionId });
        }
      },
      onError: () => {},
    },
  );

  const handleCloseAddNewCard = () => {
    setIsOpenAddNewCard(false);
    setIsOpenPaymentMethod(true);
  };

  const handleSelectWallet = (walletId: string) => {
    const wallet = $vm.wallets.find((w) => w.asJson.id === walletId);
    if (wallet) {
      setActiveWallet(wallet);
      setActiveProduct($vm.getProductByCurrency(wallet.asJson.currency) || null);
      setIsOpenWalletsDialog(false);
      setValue(nameOf<ITopUpForm>('walletId'), wallet.asJson.id);
      trigger(nameOf<ITopUpForm>('walletId'));
      if (amount) {
        $vm.checkLimits(amountToNumber(amount), wallet.asJson.currency);
      }
    }
  };

  useEffect(() => {
    if (defaultCurrency && !activeWallet) {
      const wallet = $vm.wallets.find((w) => w.asJson.currency === defaultCurrency);
      if (wallet) {
        handleSelectWallet(wallet.asJson.id);
      }
    }
  }, [defaultCurrency, $vm.wallets.length]);

  const handleSelectAmount = (value: string) => {
    setAmount(value);
    setValue(nameOf<ITopUpForm>('amount'), amountToNumber(value));
    trigger(nameOf<ITopUpForm>('amount'));
    $vm.checkLimits(
      amountToNumber(value),
      activeWallet ? activeWallet.asJson.currency : $vm.currency,
    );
  };

  const toggleIsOpenPaymentMethod = (isOpen: boolean) => {
    if ($vm.isActiveMaintenanceMode) {
      setIsOpenPaymentMethod(false);
      return;
    }
    setIsOpenPaymentMethod(isOpen);
  };

  const onSelectPaymentMethod = (paymentMethod: SelectedPaymentMethod) => {
    setSelectedPaymentMethod(paymentMethod);
    setValue(nameOf<ITopUpForm>('paymentMethodId'), paymentMethod.paymentMethod.asJson.id);
    trigger(nameOf<ITopUpForm>('paymentMethodId'));
    setIsOpenPaymentMethod(false);
  };

  const onSubmitFrom = (data: ITopUpForm) => {
    createDepositWalletMutation.mutate(data);
  };
  const { pathname } = useLocation();

  const onCloseVerificationDialog = (result: boolean) => {
    if (result) {
      if (isMobile) {
        $vm.saveState(getValues());
        navigate(ROUTES.verification, { state: { redirect: pathname } });
      } else {
        setIsOpenKYCDialog(true);
      }
    }
    setIsOpenVerificationDialog(false);
  };

  const onCloseKYCDialog = () => {
    setIsOpenKYCDialog(false);
  };

  const closeBlockUserErrorDialog = () => {
    setBlockUserErrorMessage('');
    $vm.signOut();
    navigate(ROUTES.public.login());
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLFormElement>) => {
    if (e.code === 'Enter') {
      handleSubmit(onSubmitFrom)();
    }
  };

  const maintenanceModeBlock = (
    <div className={classes.limitInfoBlock}>
      <span className={classes.limitInfoError}>{t`System is under maintenance.`}</span>
      <span className={classes.seeDetails} onClick={handleMaintenancePage}>{t`See details`}</span>
    </div>
  );

  const limitsError = (
    <div className={classes.limitInfoBlock}>
      <span className={classes.limitInfoError}>{$vm.limitError}.</span>
      <span
        className={classes.seeDetails}
        onClick={() => setIsOpenLimitsInfo(true)}
      >{t`See details`}</span>
    </div>
  );

  const limitsInfo = (
    <div className={classes.limitInfoBlock}>
      <span className={classes.limitInfo}>{t`System limits for depositing.`}</span>
      <span
        className={classes.seeDetails}
        onClick={() => setIsOpenLimitsInfo(true)}
      >{t`See details`}</span>
    </div>
  );

  let infoComponent = <></>;

  switch (true) {
    case $vm.isActiveMaintenanceMode:
      infoComponent = maintenanceModeBlock;
      break;
    case Boolean($vm.limitError):
      infoComponent = limitsError;
      break;
    default:
      infoComponent = limitsInfo;
      break;
  }

  let paymentComponent = <div></div>;
  if ($vm.activePaymentSystem() === PaymentProviderEnum.STRIPE) {
    paymentComponent = (
      <AddCardStripe
        showSave
        isOpen={isOpenAddNewCard}
        loading={addCardMutation.isLoading}
        onSave={(paymentInfo, save) => addCardMutation.mutate({ paymentInfo, save })}
        onCancel={handleCloseAddNewCard}
        confirmButtonLabel={t`Proceed`}
      />
    );
  } else if ($vm.activePaymentSystem() === PaymentProviderEnum.WORLDPAY) {
    paymentComponent = (
      <AddCardWorldpay
        showSave
        isOpen={isOpenAddNewCard}
        loading={addCardMutation.isLoading}
        onSave={(paymentInfo, save) => addCardMutation.mutate({ paymentInfo, save })}
        onCancel={handleCloseAddNewCard}
        confirmButtonLabel={t`Proceed`}
      />
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.formBlock}>
        <form
          onSubmit={handleSubmit(onSubmitFrom)}
          onKeyDown={handleKeyPress}
          className={classes.form}
        >
          <div>
            <div className={classes.backButton}>
              <BackButton onClick={navigateToPreviousStep} />
            </div>
            <div className={classes.title}>{t`Deposit account`}</div>
            {$vm.document ? (
              <div
                className={classes.hintButton}
                onClick={() => setIsOpenDocumentDialog(true)}
              ></div>
            ) : (
              <></>
            )}
            <div className={classes.fields}>
              <div className={classes.amountBlock}>
                <div className={classes.switchButton}>
                  <img src={String(ArrowIcon)} />
                </div>
                <div className={classes.field}>
                  <InputSelectButton
                    title={t`Account`}
                    value={activeWallet ? activeWallet.asJson.currency : ''}
                    description={'Top-up currency'}
                    onToggle={() =>
                      $vm.isActiveMaintenanceMode ? () => {} : setIsOpenWalletsDialog(true)
                    }
                    key={'select-button'}
                  />
                </div>
                <div className={classes.field}>
                  <InputCurrency
                    resetValidation={() => {}}
                    digitsCountAfterDot={2}
                    autoFocus={true}
                    inputRef={inputRef}
                    currency={t`Amount`}
                    disabled={$vm.isActiveMaintenanceMode}
                    label={t`Amount to top-up`}
                    value={amount}
                    onChangeValue={handleSelectAmount}
                    error={Boolean($vm.limitError)}
                    key={'amount'}
                  />
                </div>
              </div>
              <SelectPaymentMethod
                wallets={[]}
                onAddNewCard={() => setIsOpenAddNewCard(true)}
                cards={$vm.cards}
                isPurchase={true}
                isOpenPaymentMethod={isOpenPaymentMethod}
                toggleIsOpenPaymentMethod={toggleIsOpenPaymentMethod}
                defaultPaymentMethod={selectedPaymentMethod}
                onSelectPaymentMethod={onSelectPaymentMethod}
              />
              <div className={classes.equivalent}>
                {activeProduct
                  ? `* 1 ${activeProduct.asJson.baseCurrency} = 1 ${activeProduct.asJson.name}`
                  : ''}
              </div>
            </div>
          </div>
          <div>
            <div>
              {infoComponent}
              <SubmitButton
                label={t`Continue`}
                disabled={
                  $vm.amountError ||
                  $vm.isActiveMaintenanceMode ||
                  !Boolean(activeWallet) ||
                  !Boolean(selectedPaymentMethod) ||
                  !amount
                }
                isLoading={
                  downloadWalletsQuery.isFetching ||
                  fetchMaintenanceModeStatusQuery.isFetching ||
                  downloadCardsQuery.isFetching ||
                  downloadLimitsQuery.isFetching ||
                  createDepositWalletMutation.isLoading ||
                  confirmMutation.isLoading
                }
                onSubmit={handleSubmit(onSubmitFrom)}
              />
            </div>
          </div>
        </form>
      </div>
      {paymentComponent}
      <LimitsInfoDialog
        isOpen={isOpenLimitsInfo}
        onClose={() => setIsOpenLimitsInfo(false)}
        vm={$vm}
      />
      <WalletsDialog
        title={t`Choose Account to Top up`}
        description={t`Wealthstack currency accounts like an original bank account can be used for Savings, Transfers or as a Payment Method to purchase Gold.`}
        isOpen={isOpenWalletsDialog}
        metals={[]}
        wallets={$vm.wallets
          .filter((w) => $vm.getProductTypeByCurrency(w.asJson.currency) === ProductType.FIAT_COIN)
          .sort(
            (prev, next) =>
              ($vm.getProductByCurrency(next.asJson.currency)?.asJson.viewPriority || 0) -
              ($vm.getProductByCurrency(prev.asJson.currency)?.asJson.viewPriority || 0),
          )}
        onClose={() => setIsOpenWalletsDialog(false)}
        onSelectWallet={handleSelectWallet}
      />
      <VerificationDialogDialog
        isOpen={isOpenVerificationDialog}
        onClose={onCloseVerificationDialog}
      />
      <KYCDialogDialog isOpen={isOpenKYCDialog} onClose={onCloseKYCDialog} />
      <BlockUserErrorDialog
        message={blockUserErrorMessage}
        onClose={closeBlockUserErrorDialog}
        isOpen={Boolean(blockUserErrorMessage)}
      />
      <DocumentDialog
        isOpen={isOpenDocumentDialog}
        onClose={() => setIsOpenDocumentDialog(false)}
        type={DocumentsTitle.DEPOSIT}
        title={t`Transaction Information`}
        url={$vm.document?.url || ''}
      />
    </div>
  );
});
export default appWithStyles(styles)(TopUpComponent);
