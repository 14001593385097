import React from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import { Tabbar } from '../tabbar';

import { styles } from './tabbar-container.styles';

export interface TabbarContainerProps extends AppWithStyles<typeof styles> {
  children?: React.ReactNode;
}

const TabbarContainerComponent: React.FC<TabbarContainerProps> = ({ classes, children }) => {
  return (
    <div className={classes.root}>
      {children}
      <Tabbar />
    </div>
  );
};

export default appWithStyles(styles)(TabbarContainerComponent);
