import React from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';

import TopUp from '../top-up/top-up';

import { styles } from './top-up-mobile-container.styles';

export interface DocumentsMobileContainerProps extends AppWithStyles<typeof styles> {}

const DocumentsMobileContainerComponent: React.FC<DocumentsMobileContainerProps> = ({
  classes,
}) => {
  return (
    <div className={classes.root}>
      <TopUp />
    </div>
  );
};

export default appWithStyles(styles)(DocumentsMobileContainerComponent);
