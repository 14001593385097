import { appCreateStyles } from '@core/theme/utils/create-styles';
import ArrowRight from '@modules/new-private/settings/components/settings/img/arrow-right.svg';
import accounts from '@modules/new-private/tabbar/tabbar/img/accounts-gray.svg';
import accountsSelected from '@modules/new-private/tabbar/tabbar/img/accounts-white.svg';
import activity from '@modules/new-private/tabbar/tabbar/img/activity-gray.svg';
import activitySelected from '@modules/new-private/tabbar/tabbar/img/activity-white.svg';
import Logo from '@modules/new-private/tabbar/tabbar/img/logo.svg';
import market from '@modules/new-private/tabbar/tabbar/img/market-gray.svg';
import marketSelected from '@modules/new-private/tabbar/tabbar/img/market-white.svg';
import settings from '@modules/new-private/tabbar/tabbar/img/settings-gray.svg';
import settingsSelected from '@modules/new-private/tabbar/tabbar/img/settings-white.svg';
import { Theme } from '@mui/material';
import { _var, CSS_VARIABLES, Layout } from '@shared/utils/layout';

export function styles({ breakpoints }: Theme) {
  return appCreateStyles({
    root: {
      height: '85px',
      width: '100%',
      display: 'block',
      zIndex: '999',
      position: 'fixed',
      bottom: '0px',
      left: '0px',
      background: 'transparent',
      [breakpoints.up(Layout.laptop)]: {
        display: 'none',
      },
    },
    navigation: {
      borderTop: '1px solid rgba(255, 255, 255, 0.02)',
      background: 'rgba(31, 31, 57, 0.20)',
      backdropFilter: 'blur(30px)',
      display: 'block',
      height: '85px',
      width: '100%',
    },
    actions: {
      top: '85px',
      width: '100%',
      position: 'absolute',
      height: `calc(${_var(CSS_VARIABLES.appHeight)} * 0.9)`,
      borderRadius: '35px 35px 0px 0px',
      borderTop: '2px solid #6B39F4',
      background: '#0D0A15',
      transition: 'top 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    title: {
      color: '#fff',
      textAlign: 'center',
      fontSize: '28px',
      fontWeight: 500,
    },
    description: {
      marginTop: '16px',
      color: '#fff',
      textAlign: 'center',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
      letterSpacing: '0.2px',
    },
    actionButtons: {
      marginTop: '27px',
      display: 'flex',
      justifyContent: 'space-evenly',
    },
    menu: {
      width: '100%',
      height: '42px',
    },
    itemList: {
      display: 'flex',
      width: '100%',
      height: 'fit-content',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0px 20px',
      marginTop: '10px',
    },
    item: {
      paddingTop: '27px',
      width: 'auto',
      height: '100%',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column-reverse',
      alignItems: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundPositionX: 'center',
      backgroundPositionY: 'top',
      backgroundSize: '24px',
      color: '#5A5E6B',
      fontSize: '10px',
      fontWeight: 500,
      letterSpacing: '0.5px',
      textDecoration: 'none',
    },
    accounts: {
      backgroundImage: `url(${accounts})`,
      '&$selected': {
        backgroundImage: `url(${accountsSelected})`,
        color: '#fff',
      },
    },
    activity: {
      backgroundImage: `url(${activity})`,
      '&$selected': {
        backgroundImage: `url(${activitySelected})`,
        color: '#fff',
      },
    },
    market: {
      backgroundImage: `url(${market})`,
      '&$selected': {
        backgroundImage: `url(${marketSelected})`,
        color: '#fff',
      },
    },
    settings: {
      backgroundImage: `url(${settings})`,
      '&$selected': {
        backgroundImage: `url(${settingsSelected})`,
        color: '#fff',
      },
    },
    selected: {},
    logo: {
      width: '43px',
      height: '43px',
      borderRadius: '50%',
      backgroundImage: `url(${Logo})`,
    },
    section: {
      marginTop: '20px',
      padding: '0px 17px',
      '& ul': {
        paddingLeft: '0px',
      },
    },
    sectionTitle: {
      color: '#778',
      fontSize: '14px',
      fontWeight: 600,
      letterSpacing: '0.2px',
      paddingLeft: '10px',
      marginBottom: '16px',
    },
    actionsMenu: {
      marginTop: '16px',
    },
    menuItem: {
      backgroundColor: '#21203B',
      height: '48px',
      width: '100%',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0px 16px',

      '& + &': {
        borderTop: '0.5px solid rgba(255, 255, 255, 0.10)',
      },
      '&:first-of-type': {
        borderRadius: '12px 12px 0px 0px',
      },
      '&:last-of-type': {
        borderRadius: '0px 0px 12px 12px',
      },
      '&:first-of-type&:last-of-type': {
        borderRadius: '12px 12px 12px 12px',
      },
    },
    withArrow: {
      cursor: 'pointer',
      backgroundImage: `url(${ArrowRight})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right 16px center',
      paddingRight: '36px !important',
    },
    fieldName: {
      overflow: 'hidden',
      color: '#fff',
      fontFeatureSettings: "'clig' off, 'liga' off",
      textOverflow: 'ellipsis',
      wordBreak: 'keep-all',
      whiteSpace: 'nowrap',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '22px',
      letterSpacing: '0.3px',
      minWidth: 'fit-content',
    },
    fieldIcon: {
      marginRight: '9px',
      display: 'flex',
      alignItems: 'center',
      width: '18px',
    },
    fieldValue: {
      color: '#778',
      fontSize: '14px',
      fontWeight: 600,
      letterSpacing: '0.2px',
      maxWidth: '100%',
      textWrap: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      wordBreak: 'keep-all',
      paddingLeft: '10px',
    },
    flex: {
      display: 'flex',
    },
    visibleBlock: {
      height: 'fit-content',
      padding: '48px 0px 39px 0px',
      width: '100%',
      maxHeight: '77%',
      overflow: 'auto',
    },
    maintenanceLabel: {
      width: '100%',
      height: 'fit-content',
      marginTop: '13px',
      marginBottom: '28px',
    },
  });
}
