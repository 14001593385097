import React, { useEffect, useMemo, useRef, useState } from 'react';

import { appObserver } from '@core/state-management/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { TabbarViewModel } from '@modules/new-private/tabbar/tabbar/tabbar.vm';
import { Theme, useMediaQuery } from '@mui/material';
import { KYCDialogDialog } from '@shared/components/new-design/kyc-dialog/kyc-dialog';
import { MaintenanceModeLabel } from '@shared/components/new-design/maintenance-mode-label';
import { VerificationDialogDialog } from '@shared/components/new-design/verification-dialog/verification-dialog';
import { AddCardStripe } from '@shared/components/payments/stripe/add-card-stripe-component';
import { AddCardWorldpay } from '@shared/components/payments/worldpay/add-card-worldpay-component';
import { NavLink, useNavigate } from '@shared/components/router';
import { ROUTES } from '@shared/constants/routes';
import { ProductType } from '@shared/models/products/product-model';
import { SmartCoinOrderType } from '@shared/models/smart-coin-order-status/smart-coin-order-type';
import { PaymentProviderEnum } from '@shared/types/payments/payment-provider.enum';
import { _var, CSS_VARIABLES, Layout } from '@shared/utils/layout';
import { getFirstSmartCoinCurrencyName } from '@shared/utils/products';
import classNames from 'classnames';
import { useMutation, useQuery } from 'react-query';

import AddCardIcon from './img/add-card.svg';
import BuyGoldIcon from './img/buy-gold.svg';
import DepositIcon from './img/deposit.svg';
import SellGoldIcon from './img/sell-gold.svg';
import SendGoldIcon from './img/send-gold.svg';
import WithdrawIcon from './img/withdraw.svg';

import { styles } from './tabbar.styles';

export interface TabbarProps extends AppWithStyles<typeof styles> {}

const TabbarComponent: React.FC<TabbarProps> = appObserver(({ classes }) => {
  const actionMenuRef = useRef<HTMLDivElement>(null);
  const backdropRef = useRef<HTMLDivElement>(null);
  const [isActiveMenu, setIsActiveMenu] = useState(false);
  const [isOpenAddNewCard, setIsOpenAddNewCard] = useState(false);
  const [isOpenKYCDialog, setIsOpenKYCDialog] = useState<boolean>(false);
  const [isOpenVerificationDialog, setIsOpenVerificationDialog] = useState<boolean>(false);
  const [redirectRoute, setRedirectRoute] = useState<string>('');

  const navigate = useNavigate();

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Layout.tablet));
  const $vm = useMemo(() => new TabbarViewModel(), []);

  useEffect(() => {
    $vm.checkMaintenanceStatus();
  }, [isActiveMenu]);

  const addCardMutation = useMutation(
    (data: { paymentInfo: any; save: boolean }) =>
      $vm.handleAddNewCard(data.paymentInfo, data.save),
    {
      onSuccess: (paymentMethod) => {
        if (paymentMethod) {
          setIsOpenAddNewCard(false);
          $vm.showSuccess(t`Card successfully added.`);
        }
      },
      onError: () => {
        $vm.showError(t`Your card was declined.`);
      },
    },
  );

  useQuery(['fetch-maintenance-mode-status'], () => $vm.fetchMaintenanceModeStatus(), {
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
  });

  const activateMenu = () => {
    setIsActiveMenu(true);
    if (backdropRef?.current?.style) {
      backdropRef.current.style.display = 'block';
    }
    if (actionMenuRef?.current?.style) {
      actionMenuRef.current.style.top = `calc(${_var(CSS_VARIABLES.appHeight)} * -0.7)`;
    }
  };

  const onCloseKYCDialog = () => {
    setIsOpenKYCDialog(false);
  };

  const onCloseVerificationDialog = (result: boolean) => {
    if (result) {
      if (isMobile) {
        navigate(ROUTES.verification, { state: { redirect: redirectRoute } });
      } else {
        setIsOpenKYCDialog(true);
      }
    }
    setIsOpenVerificationDialog(false);
  };

  const deactivateMenu = () => {
    setIsActiveMenu(false);
    if (actionMenuRef?.current?.style) {
      actionMenuRef.current.style.top = '85px';
    }
    if (backdropRef?.current?.style) {
      backdropRef.current.style.display = 'none';
    }
  };

  const toggleMenu = () => {
    isActiveMenu ? deactivateMenu() : activateMenu();
  };

  const handleClickBuyButton = () => {
    if ($vm.isNeedKYC) {
      setRedirectRoute(
        ROUTES.mobilePrivate.wallets.children.order(
          SmartCoinOrderType.PURCHASE,
          getFirstSmartCoinCurrencyName($vm.products),
        ),
      );
      setIsOpenVerificationDialog(true);
    } else {
      navigate(
        ROUTES.mobilePrivate.wallets.children.order(
          SmartCoinOrderType.PURCHASE,
          getFirstSmartCoinCurrencyName($vm.products),
        ),
      );
    }
    deactivateMenu();
  };

  const handleClickSellButton = () => {
    if ($vm.isNeedKYC) {
      setRedirectRoute(
        ROUTES.mobilePrivate.wallets.children.order(
          SmartCoinOrderType.SELL,
          getFirstSmartCoinCurrencyName($vm.products),
        ),
      );
      setIsOpenVerificationDialog(true);
    } else {
      navigate(
        ROUTES.mobilePrivate.wallets.children.order(
          SmartCoinOrderType.SELL,
          getFirstSmartCoinCurrencyName($vm.products),
        ),
      );
    }
    deactivateMenu();
  };

  const handleSendButton = () => {
    navigate(ROUTES.mobilePrivate.transfer);
    deactivateMenu();
  };

  const handleOnClickDepositAccount = (currency: string) => {
    if ($vm.isNeedKYC) {
      setRedirectRoute(ROUTES.mobilePrivate.topUpByCurrency(currency));
      setIsOpenVerificationDialog(true);
    } else {
      navigate(ROUTES.mobilePrivate.topUpByCurrency(currency));
    }
    deactivateMenu();
  };

  const handleOnClickWithdrawAccount = (currency: string) => {
    navigate(
      ROUTES.mobilePrivate.wallets.children.order(SmartCoinOrderType.SELL_FIAT_TOKEN, currency),
    );
    deactivateMenu();
  };

  let paymentComponent = <div></div>;
  if ($vm.activePaymentSystem() === PaymentProviderEnum.STRIPE) {
    paymentComponent = (
      <AddCardStripe
        showSave
        isOpen={isOpenAddNewCard}
        loading={addCardMutation.isLoading}
        onSave={(paymentInfo, save) => addCardMutation.mutate({ paymentInfo, save })}
        onCancel={() => setIsOpenAddNewCard(false)}
        confirmButtonLabel={t`Proceed`}
      />
    );
  } else if ($vm.activePaymentSystem() === PaymentProviderEnum.WORLDPAY) {
    paymentComponent = (
      <AddCardWorldpay
        showSave
        isOpen={isOpenAddNewCard}
        loading={addCardMutation.isLoading}
        onSave={(paymentInfo, save) => addCardMutation.mutate({ paymentInfo, save })}
        onCancel={() => setIsOpenAddNewCard(false)}
        confirmButtonLabel={t`Proceed`}
      />
    );
  }

  return (
    <div className={classes.root}>
      <div ref={actionMenuRef} className={classes.actions}>
        <div className={classes.visibleBlock}>
          <div className={classes.title}>{t`Immediate actions`}</div>
          {$vm.isActiveMaintenanceMode ? (
            <div className={classes.maintenanceLabel}>
              <MaintenanceModeLabel />
            </div>
          ) : (
            <></>
          )}
          <div className={classes.section}>
            <div className={classes.sectionTitle}>{t`Actions`}</div>
            <ul className={classes.actionsMenu}>
              <li className={classes.menuItem} onClick={() => setIsOpenAddNewCard(true)}>
                <div className={classes.flex}>
                  <div className={classes.fieldIcon}>
                    <img src={String(AddCardIcon)} />
                  </div>
                  <div className={classes.fieldName}>{t`Add card`}</div>
                </div>
              </li>
            </ul>
          </div>
          <div className={classes.section}>
            <div className={classes.sectionTitle}>
              {$vm.smartCoinName} {t`Transactions`}
            </div>
            <ul className={classes.actionsMenu}>
              <li
                className={classNames(classes.menuItem, classes.withArrow)}
                onClick={() => handleClickBuyButton()}
              >
                <div className={classes.flex}>
                  <div className={classes.fieldIcon}>
                    <img src={String(BuyGoldIcon)} />
                  </div>
                  <div className={classes.fieldName}>{t`Buy gold`}</div>
                </div>
              </li>
              <li
                className={classNames(classes.menuItem, classes.withArrow)}
                onClick={() => handleSendButton()}
              >
                <div className={classes.flex}>
                  <div className={classes.fieldIcon}>
                    <img src={String(SendGoldIcon)} />
                  </div>
                  <div className={classes.fieldName}>{t`Send gold`}</div>
                </div>
              </li>
              <li
                className={classNames(classes.menuItem, classes.withArrow)}
                onClick={() => handleClickSellButton()}
              >
                <div className={classes.flex}>
                  <div className={classes.fieldIcon}>
                    <img src={String(SellGoldIcon)} />
                  </div>
                  <div className={classes.fieldName}>{t`Sell gold`}</div>
                </div>
              </li>
            </ul>
          </div>
          <div className={classes.section}>
            <div className={classes.sectionTitle}>
              {t`Fiat`} {t`Transactions`}
            </div>
            <ul className={classes.actionsMenu}>
              {$vm.products
                .filter((p) => p.asJson.type === ProductType.FIAT_COIN)
                .sort(
                  (prev, next) => (next.asJson.viewPriority || 0) - (prev.asJson.viewPriority || 0),
                )
                .map((product) => {
                  return (
                    <li
                      className={classNames(classes.menuItem, classes.withArrow)}
                      onClick={() => handleOnClickDepositAccount(product.asJson.name)}
                    >
                      <div className={classes.flex}>
                        <div className={classes.fieldIcon}>
                          <img src={String(DepositIcon)} />
                        </div>
                        <div
                          className={classes.fieldName}
                        >{t`Deposit ${product.asJson.name} account`}</div>
                      </div>
                    </li>
                  );
                })}
              {$vm.products
                .filter((p) => p.asJson.type === ProductType.FIAT_COIN)
                .sort(
                  (prev, next) => (next.asJson.viewPriority || 0) - (prev.asJson.viewPriority || 0),
                )
                .map((product) => {
                  return (
                    <li
                      className={classNames(classes.menuItem, classes.withArrow)}
                      onClick={() => handleOnClickWithdrawAccount(product.asJson.name)}
                    >
                      <div className={classes.flex}>
                        <div className={classes.fieldIcon}>
                          <img src={String(WithdrawIcon)} />
                        </div>
                        <div className={classes.fieldName}>
                          {t`Withdraw`} {product.asJson.name}
                        </div>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
      <div className={classes.navigation}>
        <div className={classes.menu}>
          <div className={classes.itemList}>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? `${classes.item} ${classes.accounts} ${classes.selected}`
                  : `${classes.item} ${classes.accounts}`
              }
              to={ROUTES.mobilePrivate.dashboard}
              onClick={() => deactivateMenu()}
            >
              {t`Accounts`}
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? `${classes.item} ${classes.market} ${classes.selected}`
                  : `${classes.item} ${classes.market}`
              }
              to={ROUTES.mobilePrivate.market}
              onClick={() => deactivateMenu()}
            >
              {t`Market`}
            </NavLink>
            <div className={classes.logo} onClick={toggleMenu}></div>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? `${classes.item} ${classes.activity} ${classes.selected}`
                  : `${classes.item} ${classes.activity}`
              }
              to={ROUTES.mobilePrivate.activity}
              onClick={() => deactivateMenu()}
            >
              {t`Activity`}
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? `${classes.item} ${classes.settings} ${classes.selected}`
                  : `${classes.item} ${classes.settings}`
              }
              to={ROUTES.mobilePrivate.settings.root}
              onClick={() => deactivateMenu()}
            >
              {t`Settings`}
            </NavLink>
          </div>
        </div>
      </div>
      {paymentComponent}
      <VerificationDialogDialog
        isOpen={isOpenVerificationDialog}
        onClose={onCloseVerificationDialog}
      />
      <KYCDialogDialog isOpen={isOpenKYCDialog} onClose={onCloseKYCDialog} />
    </div>
  );
});

export const Tabbar = appWithStyles(styles)(TabbarComponent);
