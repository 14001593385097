import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';

export function styles(theme: Theme) {
  return appCreateStyles({
    root: {
      height: '0px',
    },
    viewBlock: {
      padding: '20px 20px 20px 20px',
      color: '#fff',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    title: {
      color: '#F7F9FC',
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '165%',
      letterSpacing: '0.4px',
      marginBottom: '20px',
    },
    description: {
      color: 'rgba(255, 255, 255, 0.60)',
      textAlign: 'center',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '170%',
      letterSpacing: '0.3px',
      width: '315px',
    },
    details: {
      marginTop: '26px',
      color: 'rgba(255, 255, 255, 0.60)',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '180%',
      letterSpacing: '0.3px',
      marginBottom: '4px',
      width: '100%',
    },
    walletBlock: {
      cursor: 'pointer',
      '& + &': {
        marginTop: '20px',
      },
    },
    wallet: {
      width: '100%',
      height: '67px',
      borderRadius: '12px',
      background: '#1E1D36',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0px 20px',
    },
    currency: {
      display: 'flex',
      alignItems: 'center',
    },
    currencyFlag: {
      width: 'fit-content',
      height: 'fit-content',
    },
    currencyName: {
      marginLeft: '10px',
      color: '#FFF',
      fontSize: '15px',
      fontWeight: 600,
      lineHeight: '124%',
      letterSpacing: '0.2px',
    },
    balance: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    balanceTitle: {
      color: '#63608C',
      textAlign: 'right',
      fontSize: '13px',
      fontWeight: 400,
      letterSpacing: '0.3px',
    },
    balanceAmount: {
      color: '#FFF',
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: '0.3px',
    },
    walletDescription: {
      color: '#778',
      fontSize: '13px',
      fontWeight: 500,
      letterSpacing: '0.2px',
    },
    unactive: {
      '& $wallet': {
        background: 'rgba(255, 255, 255, 0.05)',
      },
      '& $currencyFlag': {
        opacity: 0.6,
        mixBlendMode: 'luminosity',
      },
    },
    drawer: {
      '& .MuiPaper-root': {
        maxHeight: '80%',
        height: 'auto',
        background: '#161524',
      },
    },
    drawerContent: {
      maxHeight: '100%',
      overflow: 'auto',
    },
    sectionTitle: {
      color: '#FFF',
      fontSize: '13px',
      fontWeight: 500,
      letterSpacing: '0.2px',
      marginBottom: '16px',
    },
  });
}
