import React, { FC, useMemo } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { TopUpViewModel } from '@modules/new-private/wallets/top-up/components/top-up/top-up.vm';
import { Box, Drawer, Modal, Theme, useMediaQuery } from '@mui/material';
import { CloseDrawerButton } from '@shared/components/new-design/close-drawer-button/close-drawer-button';
import AttentionSVG from '@shared/components/new-design/images/attantion-purple.svg';
import SubmitButton from '@shared/components/new-design/submit-button/submit-button';
import { Layout } from '@shared/utils/layout';

import { styles } from './limits-info-dialog.styles';

export interface LimitsInfoDialogProps extends AppWithStyles<typeof styles> {
  isOpen: boolean;
  onClose: () => void;
  vm: TopUpViewModel;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  background: '#291945',
  border: '1px solid #473763',
  borderRadius: '10px',
  '&:focus-visible': {
    outline: 'none',
  },
};

const LimitsInfoDialogComponent: FC<LimitsInfoDialogProps> = ({ classes, onClose, isOpen, vm }) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Layout.tablet));

  const $vm = useMemo(() => vm, []);

  const limitComponent = (
    <div className={classes.viewBlock}>
      <img className={classes.attentionIcon} src={String(AttentionSVG)}></img>
      <div className={classes.title}>{t`Active Limits`}</div>
      <div
        className={classes.description}
      >{t`Please note that system has MIN and MAX limits for transactions`}</div>
      <div className={classes.details}>
        <div className={classes.paymentCard}>
          <span className={classes.bold}>{t`from Payment Card`}</span>
          <br />
          <span className={classes.bold}>{t`Min`}</span> {$vm.minCardLimit} |{' '}
          <span className={classes.bold}>{t`Max`}</span> {$vm.maxCardLimit}{' '}
          {t`of Transaction Currency`}
        </div>
        <div className={classes.systemWallet}>
          <span className={classes.bold}>{t`from Other sources`}</span>
          <br />
          <span className={classes.bold}>{t`Min`}</span> {$vm.minWalletLimit} |{' '}
          <span className={classes.bold}>{t`Max`}</span> {$vm.maxWalletLimit}{' '}
          {t`of Transaction Currency`}
        </div>
      </div>
      <div className={classes.submit}>
        <SubmitButton label={t`Close`} onSubmit={onClose} />
      </div>
    </div>
  );

  let content = <></>;

  if (isMobile) {
    content = (
      <Drawer anchor={'bottom'} open={isOpen} className={classes.drawer} onClose={onClose}>
        <div className={classes.drawerContent}>{limitComponent}</div>
        <CloseDrawerButton onClick={onClose} />
      </Drawer>
    );
  } else {
    content = (
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>{limitComponent}</Box>
      </Modal>
    );
  }
  return <div className={classes.root}>{content}</div>;
};

export default appWithStyles(styles)(LimitsInfoDialogComponent);
